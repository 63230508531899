define("discourse/plugins/discourse-epic-games/discourse/components/nav-link-dropdown", ["exports", "@ember/object", "@ember/service", "@ember-decorators/component", "discourse/models/nav-item", "select-kit/components/combo-box", "select-kit/components/select-kit"], function (_exports, _object, _service, _component, _navItem, _comboBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NavLinkDropdown = dt7948.c(class NavLinkDropdown extends _comboBox.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "topicTrackingState", [_service.service]))();
    #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
    nameProperty = "displayName";
    get content() {
      const allowedNavItems = ["new", "unread", "top"];
      const currentRouteQueryParams = this.router.currentRoute.queryParams;
      const tagId = this.topicTrackingState.filterTag;
      const siteSettings = this.siteSettings;
      const navItems = _navItem.default.buildList(this.category, {
        currentRouteQueryParams,
        tagId,
        siteSettings
      }).filter(item => allowedNavItems.includes(item.name)).map((item, index) => {
        let href;
        const currentTag = item.tagId ? item.tagId : item.currentRouteQueryParams.tag;
        if (item.name === "new") {
          href = `/tag/${currentTag}/l/new`;
        } else if (item.name === "unread") {
          href = `/tag/${currentTag}/l/unread`;
        } else if (item.name === "top") {
          href = `/tag/${currentTag}/l/top`;
        } else {
          href = "#";
        }
        if (item.name === "unread" && item.count === 0) {
          return;
        }
        return {
          id: index,
          name: item.name,
          title: item.title,
          displayName: item.displayName,
          value: item.value,
          href
        };
      });
      return navItems;
    }
    static #_4 = (() => dt7948.n(this.prototype, "content", [(0, _object.computed)("category")]))();
    modifyComponentForRow() {
      return "nav-link-dropdown-row";
    }
  }, [(0, _component.classNames)("nav-link-dropdown"), (0, _selectKit.selectKitOptions)({
    icon: "ellipsis-vertical",
    caretDownIcon: null,
    caretUpIcon: null
  }), (0, _selectKit.pluginApiIdentifiers)("nav-link-dropdown")]);
  var _default = _exports.default = NavLinkDropdown;
});