define("discourse/plugins/discourse-epic-games/discourse/components/epic-pro-support-banner", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EpicProSupportBanner extends _component.default {
    get isEpicProSupport() {
      const topic = this.args.outletArgs.model;
      return topic?.tags?.some(tag => tag.toLowerCase() === "epic-pro-support");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{! template-lint-disable no-triple-curlies }}
        {{#if this.isEpicProSupport}}
          <div class="epic-support-pro-banner">
            <span class="topic-status-icon">
              {{icon "ue-home"}}
            </span>
            {{{i18n
              "discourse_epic_games.topic_title.epic_pro_support_banner"
              tag="<a href='/tags/epic-pro-support'>Epic Pro Support</a>"
            }}}
          </div>
        {{/if}}
      
    */
    {
      "id": "Y5ldzum7",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"isEpicProSupport\"]],[[[1,\"      \"],[10,0],[14,0,\"epic-support-pro-banner\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"topic-status-icon\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"ue-home\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[2,[28,[32,1],[\"discourse_epic_games.topic_title.epic_pro_support_banner\"],[[\"tag\"],[\"<a href='/tags/epic-pro-support'>Epic Pro Support</a>\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/epic-pro-support-banner.js",
      "scope": () => [_dIcon.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EpicProSupportBanner;
});