define("discourse/plugins/discourse-epic-games/discourse/helpers/epic-avatar-highlight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = epicAvatarHighlight;
  function epicAvatarHighlight(user) {
    const badges = user?.badges || user?.unreal_badges;
    if (!badges || typeof badges !== "string") {
      return "";
    }
    const badgeIds = badges.split(",").map(e => parseInt(e, 10));
    let badgeName;
    if (badgeIds.includes(1)) {
      badgeName = "staff";
    } else if (badgeIds.includes(0)) {
      badgeName = "hero";
    } else if (badgeIds.includes(2)) {
      badgeName = "partner";
    }
    if (!badgeName) {
      return "";
    }
    return "epic-highlight-" + badgeName;
  }
});