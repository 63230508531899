define("discourse/plugins/discourse-epic-games/discourse/initializers/epic-profile-expander", ["exports", "@glimmer/tracking", "discourse/lib/api", "discourse/lib/decorators"], function (_exports, _tracking, _api, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // always show expanded profiles
  var _default = _exports.default = (0, _api.apiInitializer)("1.6.1", api => {
    api.modifyClass("controller:user", Superclass => class extends Superclass {
      static #_ = (() => dt7948.g(this.prototype, "forceExpand", [_tracking.tracked], function () {
        return true;
      }))();
      #forceExpand = (() => (dt7948.i(this, "forceExpand"), void 0))();
      canExpandProfile() {
        return true;
      }
      static #_2 = (() => dt7948.n(this.prototype, "canExpandProfile", [(0, _decorators.default)("viewingSelf", "model.profile_hidden")]))();
      collapsedInfo(profileHidden, isSummaryRoute, viewingSelf, forceExpand) {
        if (profileHidden) {
          return true;
        }
        return !forceExpand;
      }
      static #_3 = (() => dt7948.n(this.prototype, "collapsedInfo", [(0, _decorators.default)("model.profile_hidden", "isSummaryRoute", "viewingSelf", "forceExpand")]))();
    });
  });
});