define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-title/epic-pro-support-banner-wrapper", ["exports", "@glimmer/component", "discourse/plugins/discourse-epic-games/discourse/components/epic-pro-support-banner", "@ember/component", "@ember/template-factory"], function (_exports, _component, _epicProSupportBanner, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-empty-glimmer-component-classes */

  class EpicProSupportBannerWrapper extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <EpicProSupportBanner @outletArgs={{@outletArgs}} />
      
    */
    {
      "id": "eBQKSHZ5",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@outletArgs\"],[[30,1]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-title/epic-pro-support-banner-wrapper.js",
      "scope": () => [_epicProSupportBanner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EpicProSupportBannerWrapper;
});