define("discourse/plugins/discourse-epic-games/discourse/helpers/poster-badges", ["@ember/template", "discourse/lib/raw-handlebars"], function (_template, _rawHandlebars) {
  "use strict";

  _rawHandlebars.default.registerHelper("poster-badges", function (args) {
    // it is not ideal to have to dig into the poster param as so,
    // but it is the only way to get the user object since we are required
    // to override a hbr file
    const unrealBadges = args.hashContexts.poster?.poster?.user?.unreal_badges;
    if (!unrealBadges) {
      // if there are no badges, return null which will render nothing
      return null;
    }
    const className = posterClass(unrealBadges);
    const div = `<div class="unreal-highlight-${className}"></div>`;
    return (0, _template.htmlSafe)(div);
  });
  function posterClass(unrealBadges) {
    if (unrealBadges) {
      // always return staff badge or hero badge first
      if (unrealBadges?.includes(1)) {
        return "staff";
      } else if (unrealBadges?.includes(0)) {
        return "hero";
      } else if (unrealBadges?.includes(2)) {
        return "partner";
      }
    }
  }
});