define("discourse/plugins/discourse-epic-games/discourse/components/nav-link-dropdown-row", ["exports", "@ember-decorators/component", "discourse/lib/url", "select-kit/components/select-kit/select-kit-row"], function (_exports, _component, _url, _selectKitRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NavLinkDropdownRow = dt7948.c(class NavLinkDropdownRow extends _selectKitRow.default {
    get label() {
      return this.item.displayName;
    }
    click(event) {
      event.preventDefault();
      event.stopPropagation();
      _url.default.routeTo(this.item.href);
      return false;
    }
  }, [(0, _component.classNames)("nav-link-dropdown-row")]);
  var _default = _exports.default = NavLinkDropdownRow;
});